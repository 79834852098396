import '../../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import BannerComponent from '../banner';
import ProgramComponent from '../program';
import AlumniComponent from '../alumni';
import BootCampComponent from '../bootcamp';
import ExploreComponent from '../explore';
import FooterComponent from '../footer';
import InfoComponent from '../infobox';
import MasterComponent from '../master';
import TestimonialComponent from '../testimonial';
import CountComponent from '../numbers';
import TrainerComponent from '../trainers';
import SocialComponent from '../social';
import TrueFooterComponent from '../TrueFooter';
import TalkComponent from '../talk';
import HeaderComponent from '../header';
import WhyChooseComponent from '../whychoose';
import useDocumentTitle from '../useDocumentTitle';
import SeoComponent from '../helmet/SeoComponent';
import { useSelector } from 'react-redux';
import React,{Suspense,lazy} from 'react';

// const BannerComponent = lazy(()=>import('../banner'));
const HomeComponent = () =>{
    const { usersData, isLogin,cartCount,currency,currencyValue } = useSelector((state) => state.LoginLogout);
    // useDocumentTitle("CADBench-Top Online CAD Training & Certification Institute");
 
    return (
        <div className="App">
            <SeoComponent title="CADBench-Top Online CAD Training & Certification" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            {/* <Testapicall/> */}
            <HeaderComponent isHome="true"/>
            {/* <Suspense fallback={<div>Loading...</div>}>
                <BannerComponent/>
            </Suspense> */}
            <BannerComponent/>
            <ProgramComponent searchContent="" source="home"/>
            <CountComponent/>
            <WhyChooseComponent/>
            <BootCampComponent/>
            <InfoComponent/>
            <TrainerComponent/>
            <ExploreComponent/>
            
            <TalkComponent/>
            <AlumniComponent/>
            <MasterComponent/>
            <TestimonialComponent/>
            <FooterComponent/>
            <SocialComponent/>
            <TrueFooterComponent/>
        </div>
      );

}
export default HomeComponent